export default function Walking() {
    return (
        <div className="relative w-full h-screen overflow-hidden">
            <div className="absolute inset-0 bg-[url('images/walking.jpg')] bg-cover bg-center"></div>
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            <div className="relative z-10 flex flex-col justify-end h-full p-8 text-white">
                <img src={"images/logo.png"} className="w-64 object-cover"  alt={"Logo"}/>
                <h2 className="text-4xl font-semibold mb-4 ml-4">Healthcare Transformation</h2>
                <div className="flex justify-between">
                <p className="max-w-sm ml-4 text-lg mb-6">
                    Over the past 3 editions, Health Expo Iraq has evolved into a pivotal platform for unveiling the
                    latest advancements in healthcare technology, reflecting the dynamic shifts in the sector.
                </p>
                <button className="gradient justify-self-end translate-y-12 mr-40 text-white rounded-full text-lg font-semibold hover:bg-teal-700 transition-colors px-10 py-1 w-96 sm:h-10 h-20">
                    <p className="font-black text-xl">Learn More</p>
                </button>
            </div>
            </div>
            </div>
    );
}