import React from 'react';
import { Menu, X } from 'lucide-react';

const Header = ({ openBookingModal, isMenuOpen, setIsMenuOpen }) => {

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <header className="bg-teal-600 text-white p-4">
            <div className="container mx-auto flex justify-between items-center">
                <div className="hidden xl:flex items-center">
                    <img src="images/logo.png" alt="Health Expo Iraq" className="h-10 md:h-15" />
                </div>

                <button onClick={toggleMenu} className="xl:hidden bg-teal-600">
                    {isMenuOpen ? <X size={32} /> : <Menu size={32} />}
                </button>

                <nav className="hidden xl:flex space-x-2">
                    <a href="#" className="hover:text-teal-200">HOME</a>
                    <a href="#" className="hover:text-teal-200">OVERVIEW</a>
                    <a href="#" className="hover:text-teal-200">WHY ?</a>
                    <a href="#" className="hover:text-teal-200">CONFERENCE</a>
                    <a href="#" className="hover:text-teal-200">TRAVEL</a>
                    <a href="#" className="hover:text-teal-200">SPONSORSHIPS</a>
                    <a href="#" className="hover:text-teal-200">CONTACT</a>
                </nav>

                <button
                    onClick={function() {
                        openBookingModal()
                        setIsMenuOpen(false)
                    }}
                    className="xl:block bg-gradient-to-b from-teal-500 to-teal-600 text-white border border-white py-2 px-4 rounded-full text-sm font-bold hover:scale-105 transition duration-300"
                >
                    Book Your Stand !
                </button>
            </div>

            {isMenuOpen && (
                <nav className="xl:hidden overflow-y-hidden mt-4 flex flex-col space-y-2">
                    <a href="#" className="hover:text-teal-200">HOME</a>
                    <a href="#" className="hover:text-teal-200">OVERVIEW</a>
                    <a href="#" className="hover:text-teal-200">WHY ?</a>
                    <a href="#" className="hover:text-teal-200">CONFERENCE</a>
                    <a href="#" className="hover:text-teal-200">TRAVEL</a>
                    <a href="#" className="hover:text-teal-200">SPONSORSHIPS</a>
                    <a href="#" className="hover:text-teal-200">CONTACT</a>
                </nav>
            )}
        </header>
    );
};

export default Header;