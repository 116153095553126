import React from 'react';
import { Calendar, MapPin, X } from 'lucide-react';

const Home = ({ isOpen, setIsOpen, availableIds, unavailableIds, formData, handleInputChange, handleSubmit, selected, setSelected }) => {
    const allIds = [...availableIds, ...unavailableIds].sort((a, b) => a - b);
    return (
        <div className="relative w-full min-h-screen font-sans">
            <div className="relative bg-[url('images/doctor.jpg')] bg-cover bg-center md:bg-right w-full min-h-screen md:h-[550px] p-4 md:p-8">
                <div className="absolute top-4 left-4 bg-teal-700 text-center text-sm md:text-2xl text-white px-2 py-1 md:px-4 md:py-2 rounded-full">
                    XXm-XXd-XXh-XXmin-XXsec
                </div>

                <div className="mt-16 md:mt-24">
                    <h1 className="text-4xl md:text-6xl font-bold text-teal-600">HEALTH</h1>
                    <h1 className="text-4xl md:text-6xl font-bold text-teal-600">EXPO <span className="text-red-500">IRAQ</span></h1>
                </div>

                <div className="mt-4 font-semibold text-gray-700">
                    <p className="text-lg md:text-2xl">18 - 21 / 09 / 2024</p>
                    <p className="text-lg md:text-2xl">IRAQ, BAGHDAD</p>
                    <p className="text-lg md:text-2xl">AT BAGHDAD INTERNATIONAL FAIR</p>
                </div>

                <div className="mt-8">
                    <p className="text-teal-600 mb-2">Supporting bodies</p>
                    <div className="flex flex-wrap space-x-2 md:space-x-4">
                        <div className="w-12 h-12 md:w-16 md:h-16 bg-gray-100 rounded-full mb-2"></div>
                        <div className="w-12 h-12 md:w-16 md:h-16 bg-gray-100 rounded-full mb-2"></div>
                        <div className="w-12 h-12 md:w-16 md:h-16 bg-gray-100 rounded-full mb-2"></div>
                        <div className="w-12 h-12 md:w-16 md:h-16 bg-gray-100 rounded-full mb-2"></div>
                        <div className="w-12 h-12 md:w-16 md:h-16 bg-gray-100 rounded-full mb-2"></div>
                        <button
                            onClick={() => setIsOpen(true)}
                            className="gradient relative left-20 top-2 transform -translate-x-1/2 md:left-1/3 md:translate-x-0 bg-teal-500 text-white px-1 md:px-4 py-1 h-10 rounded-full text-base md:text-lg font-semibold hover:bg-teal-600 transition-colors">
                            Book your Stand!
                        </button>
                    </div>

                </div>


            </div>

            <div
                className="w-full h-auto md:h-[50px] bg-red-700 flex flex-col md:flex-row justify-around items-center px-4 md:px-8 py-2 md:py-0 text-white text-sm md:text-base">
                <div className="flex items-center mb-2 md:mb-0">
                    <Calendar className="mr-2" size={16}/>
                    <span>18 - 21 / 09 / 2024</span>
                </div>
                <div className="flex items-center">
                    <MapPin className="mr-2" size={16} />
                    <span>BAGHDAD INTERNATIONAL FAIR</span>
                </div>
            </div>

            {isOpen && (
                <div className="absolute top-0 z-10 inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white p-4 md:p-8 rounded-lg md:max-w-[400px] max-w-[300px]">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl md:text-2xl font-bold">Book Your Stand</h2>
                            <button onClick={() => setIsOpen(false)} className="text-black bg-transparent hover:text-gray-700">
                                <X size={24} />
                            </button>
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <input
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="w-full text-black p-2 bg-white border border-gray-300 rounded"
                            />
                            <input
                                name="email"
                                type="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-full text-black bg-white p-2 border border-gray-300 rounded"
                            />
                            <input
                                name="phone"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleInputChange}
                                className="w-full p-2 text-black bg-white border border-gray-300 rounded"
                            />
                            <div className="mt-4">
                                <p className="text-lg font-semibold mb-2">Select a stand:</p>
                                <div className="grid grid-cols-5 gap-2">
                                    {allIds.map((id) => {
                                        const isAvailable = availableIds.includes(id);
                                        return (
                                            <button
                                                key={id}
                                                type="button"
                                                className={`w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center text-xs md:text-sm font-medium ${
                                                    isAvailable
                                                        ? formData.selectedId === id.toString()
                                                            ? 'bg-teal-800 text-white'
                                                            : 'bg-teal-500 text-white hover:bg-teal-200'
                                                        : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                                }`}
                                                onClick={() => {
                                                    if (isAvailable) {
                                                        handleInputChange({
                                                            target: { name: 'selectedId', value: id.toString() }
                                                        });
                                                    }
                                                }}
                                                disabled={!isAvailable}
                                            >
                                                {id}
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-teal-500 text-white p-2 rounded hover:bg-teal-600 transition-colors"
                            >
                                Book Stand
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;