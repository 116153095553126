import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const Stats = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const stats = [
        { number: 850, label: 'Exhibitors' },
        { number: 5000, label: 'Visitors' },
        { number: 20000, label: 'Website Views' },
        { number: 50000, label: 'Reach on Social Media' },
        { number: 20, label: 'Exhibiting Countries' },
        { number: 1500, label: 'Healthcare Professionals' },
    ];

    return (
        <div ref={ref} className="relative bg-gray-900 text-white py-16">
            <div className="absolute inset-0 bg-cover bg-center opacity-30" style={{backgroundImage: "url('path/to/your/image.jpg')"}}></div>
            <div className="container mx-auto px-4 relative z-10">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                    {stats.map((stat, index) => (
                        <StatItem key={index} stat={stat} inView={inView} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const StatItem = ({ stat, inView }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (inView) {
            const interval = setInterval(() => {
                setCount(prev => {
                    const next = prev + Math.ceil(stat.number / 20);
                    return next > stat.number ? stat.number : next;
                });
            }, 50);
            return () => clearInterval(interval);
        }
    }, [inView, stat.number]);

    return (
        <div className="text-center">
            <div className="text-4xl font-bold mb-2">+{count}</div>
            <div className="text-sm">{stat.label}</div>
        </div>
    );
};

export default Stats;