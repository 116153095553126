import React from 'react';
import './NewsCard.css';

const NewsCard = () => {
    return (
        <div className="news-card">
            <h2 className="news-header">News</h2>
            <div className="image-placeholder"></div>
            <h3 className="main-title">Main Title</h3>
            <p className="news-content">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna .
            </p>
        </div>
    );
};

export default NewsCard;