import './App.css'
import Header from "./Header.jsx";
import Home from "./Home.jsx";
import {News} from "./News.jsx";
import Stats from "./Stats.jsx";
import ProductCategories from "./ProductCategories.jsx";
import HealthExpoComponent from "./HealthExpo.jsx";
import HealthExpoPromo from "./HealthExpoPromo.jsx";
import Walking from "./Walking.jsx";
import HealthExpoPartners from "./HealthExpoPartners.jsx";
import FeaturedCountries from "./FeaturedCountries.jsx";
import Gallery from "./Gallery.jsx";
import Footer from "./Footer.jsx";
import FloorPlan from "./FloorPlan.jsx";
import FeedbackGallery from "./FeedbackGallery.jsx";
import Reach from "./Reach.jsx";
import {useEffect, useState} from "react";

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const [availableIds, setAvailableIds] = useState([]);
    const [unavailableIds, setUnavailableIds] = useState([]);
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', selectedId: '' });
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [db, setDb] = useState(null);
    const [selected, setSelected] = useState(null)
    useEffect(() => {
        const request = indexedDB.open('ReservationDB', 1);

        request.onerror = (event) => {
            console.error("IndexedDB error:", event.target.error);
        };

        request.onsuccess = (event) => {
            console.log("IndexedDB opened successfully");
            setDb(event.target.result);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            const objectStore = db.createObjectStore('reservation', { keyPath: 'id' });
            objectStore.createIndex('reserved', 'reserved', { unique: false });

            // Initialize with some data
            for (let i = 1; i <= 11; i++) {
                objectStore.add({ id: i, reserved: false });
            }
        };
    }, []);

    useEffect(() => {
        if (db) {
            fetchAvailableIds();
        }
    }, [db]);

    const fetchAvailableIds = () => {
        if (!db) return;

        const transaction = db.transaction(['reservation'], 'readonly');
        const objectStore = transaction.objectStore('reservation');
        const request = objectStore.getAll();

        request.onsuccess = (event) => {
            const allReservations = event.target.result;
            const available = allReservations.filter(r => !r.reserved).map(r => r.id);
            const unavailable = allReservations.filter(r => r.reserved).map(r => r.id);
            setAvailableIds(available);
            setUnavailableIds(unavailable);
        };

        request.onerror = (event) => {
            console.error("Error fetching reservations:", event.target.error);
        };
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!db) {
            console.error("Database not initialized");
            return;
        }

        const transaction = db.transaction(['reservation'], 'readwrite');
        const objectStore = transaction.objectStore('reservation');
        const id = parseInt(formData.selectedId, 10);

        const getRequest = objectStore.get(id);

        getRequest.onsuccess = (event) => {
            const data = event.target.result;
            if (!data) {
                console.error(`No reservation found with id ${id}`);
                return;
            }
            if (data.reserved) {
                alert('This stand is already reserved.');
                return;
            }

            data.reserved = true;
            data.name = formData.name;
            data.email = formData.email;
            data.phone = formData.phone;

            const updateRequest = objectStore.put(data);

            updateRequest.onsuccess = () => {
                alert('Stand booked successfully!');
                setIsOpen(false);
                fetchAvailableIds();
            };

            updateRequest.onerror = (event) => {
                console.error('Error updating reservation:', event.target.error);
                alert('An error occurred while booking the stand.');
            };
        };

        getRequest.onerror = (event) => {
            console.error('Error fetching reservation:', event.target.error);
            alert('An error occurred while fetching the reservation data.');
        };
    };

    if (isMenuOpen) {
        return (
            <div className="max-w-full bg-teal-600 h-screen overflow-hidden">
                <Header openBookingModal={() => setIsOpen(true)} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            </div>
        );
    }
    else
        return (
            <div className="max-w-full max-h-full overflow-hidden">
                <Header openBookingModal={() => setIsOpen(true)} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
                <Home
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    availableIds={availableIds}
                    unavailableIds={unavailableIds}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    selected={selected}
                    setSelected={setSelected}
                />
                <News />
                <Stats />
                <HealthExpoComponent />
                <HealthExpoPromo/>
                <Walking />
                <HealthExpoPartners />
                <ProductCategories />
                <FeaturedCountries />
                <Reach />
                <FloorPlan />
                <FeedbackGallery />
                <Gallery />
                <Footer />
            </div>
        )
}

export default App