const FloorPlanComponent = () => {
    return (
        <div className="relative w-full h-96 overflow-hidden rounded-lg">
            <img
                src="images/img181.jpg"
                alt="Building exterior"
                className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-teal-600 to-transparent opacity-70"></div>
            <div className="absolute bottom-10 left-20 right-0 p-8 text-white">
                <h2 className="text-7xl font-bold mb-4">Floor Plan</h2>
                <button className="bg-transparent border-white text-white px-20 py-3 text-xl rounded-full transition-colors">
                    Get the Plan
                </button>
            </div>
        </div>
    );
};

export default FloorPlanComponent;