import NewsCard from "./NewsCard.jsx";
export function News() {
    return (
        <section className="bg-white m-4 md:m-7">
            <div className="bg-gray-300 max-w-full">
                <h1 className="text-black font-bold text-xl md:text-2xl p-4">News</h1>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                <NewsCard />
                <NewsCard />
                <NewsCard />
                <NewsCard />
            </div>
            <img src="images/icon.png" className="w-32 md:w-52 mx-auto mt-10 scale-125 md:scale-150" alt="Icon" />
        </section>
    )
}