import React from 'react';
import { Twitter, Facebook, Linkedin, Instagram } from 'lucide-react';

const ContactInfo = ({ country, company, name, phones, emails, website }) => (
    <div className="text-white overflow-hidden text-xs mb-4">
        <div className="flex items-center max-w-40 mb-1 border-b-2 border-white">
            <h3 className="text-white text-sm font-semibold">Contact us from {country} :</h3>
        </div>
        <p>{company}</p>
        <p>{name}</p>
        {phones.map((phone, index) => (
            <p key={index}>{phone}</p>
        ))}
        {emails.map((email, index) => (
            <p key={index}>{email}</p>
        ))}
        {website && <p>{website}</p>}
    </div>
);

const Footer = () => {
    return (
        <div className="bg-slate-800 p-4 md:p-8 font-sans">
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
                        <div className="border border-white p-4 md:p-6 inline-block mb-4">
                            <h1 className="text-white text-2xl md:text-4xl font-bold mb-2">Save the Date !</h1>
                            <p className="text-white text-xl md:text-2xl mb-2">18-21/09/2024</p>
                            <div className="text-white border border-white px-2 py-1 md:px-4 md:py-2 rounded-full inline-block text-sm md:text-base">
                                XXm-XXd-XXh-XXmin-XXsec
                            </div>
                        </div>
                        <div className="mt-8 lg:mt-80">
                            <p className="text-white text-sm mb-2">Organised by :</p>
                            <img src="/api/placeholder/150/50" alt="Business Ground Group" className="mb-4" />
                            <div className="flex space-x-4 mb-4">
                                <Twitter className="cursor-pointer hover:scale-110" size={24} color="white" />
                                <Facebook className="cursor-pointer hover:scale-110" size={24} color="white" />
                                <Linkedin className="cursor-pointer hover:scale-110" size={24} color="white" />
                                <Instagram className="cursor-pointer hover:scale-110" size={24} color="white" />
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:w-2/3">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div>
                                <ContactInfo
                                    country="Iraq"
                                    company="Business Ground Group For International Exhibitions & Conferences"
                                    name="Qamer Adel"
                                    phones={["+964 772 168 5986", "+964 783 069 4198"]}
                                    emails={["marketing@healthexpoiraq.com", "info@healthexpoiraq.com"]}
                                />
                                <ContactInfo
                                    country="Iran"
                                    company="Chista Fair"
                                    name="Hajar Hooshmandi"
                                    phones={["+98 217 5965", "+98 912 157 2130"]}
                                    emails={["chistafair@gmail.com", "info@chistafair.com"]}
                                />
                            </div>
                            <div>
                                <ContactInfo
                                    country="India"
                                    company="Exhibitions & Trade Services India Private Limited (ETSIPL)"
                                    name="Mr. Vijayanka Brighuvanshi"
                                    phones={["+91-9324232529"]}
                                    emails={["vijayanka@etsipl.in"]}
                                />
                                <ContactInfo
                                    country="North Africa"
                                    company="Expo Consultants for Fairs & Exhibitions"
                                    name="Maha Omar"
                                    phones={["Maha@expo-consultants.com", "Landline: (02) 22473193", "01111988831", "01501047818"]}
                                    emails={[]}
                                    website="www.expo-consultants.com"
                                />
                                <ContactInfo
                                    country="Jordan & Gulf Countries"
                                    company="Afaq Group for Organizing conferences and exhibitions"
                                    name="Suhair Al-Ashqar"
                                    phones={["+962 799 141 718"]}
                                    emails={["suhair@afaqgroupadv.com"]}
                                />
                            </div>
                            <div>
                                <ContactInfo
                                    country="Turkey"
                                    company="WORLDEXPO ULUSLARARASI FUARCILIK TIC. LTD. STI"
                                    name="MS YEUZ EREZ"
                                    phones={["+90(212)9800 09", "+90 533)45003 77"]}
                                    emails={["operation@worldexpofair.com"]}
                                    website="www.worldexpofair.com"
                                />
                                <ContactInfo
                                    country="China"
                                    company="IED Expo"
                                    name="Mrs. Fang Hao"
                                    phones={["+86 186 3718 0621"]}
                                    emails={["haof@ied-ltd.com"]}
                                    website="www.iedexpo.com"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;