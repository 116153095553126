import { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const feedbackItems = [
    {
        id: 1,
        name: 'H.E. Dr. Saleh Al-Hasnawi',
        image: 'images/img183.jpg',
        content: `H.E. Dr. Saleh Alhasnawi, made the opening speech of Health Expo Iraq, in which he emphasized the interest of the government authorities in pharmaceutical localization, and give it the priority. He said " the governmental program gave the priority to develop the health sector, and to the localization of pharmaceutical industry. The council of ministers voted on some supported decisions, which support the pharmaceutical localization, and private sector. He mentioned that the next stage requires more joint work, and partnerships to success the pharmaceutical localization, calling the international, and Arab companies to contribute in the localization of pharmaceutical industry. H.E. toured in the health expo Iraq, and met companies' representatives learned about their productions regarding medicines, medical appliances, and devices`,
    },
];

const FeedbackGallery = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? feedbackItems.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === feedbackItems.length - 1 ? 0 : prevIndex + 1
        );
    };

    const goToIndex = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="bg-slate-900 p-8 shadow-lg">
            <h2 className="text-4xl font-bold text-white mb-8 text-center">2023 Feedbacks</h2>
            <div className="relative overflow-hidden">
                <div className="flex justify-between items-start mb-4">
                    <div className="w-2/3 pr-8">
                        <p className="text-white text-sm mb-4">{feedbackItems[currentIndex].content}</p>
                        <h3 className="text-white font-bold">{feedbackItems[currentIndex].name}</h3>
                    </div>
                    <div className="w-1/3">
                        <img
                            src={feedbackItems[currentIndex].image}
                            alt={feedbackItems[currentIndex].name}
                            className="w-full h-auto rounded-lg"
                        />
                    </div>
                </div>
                <button
                    onClick={goToPrevious}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white/50 p-2 rounded-full z-10"
                >
                    <ChevronLeft className="text-slate-800" />
                </button>
                <button
                    onClick={goToNext}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white/50 p-2 rounded-full z-10"
                >
                    <ChevronRight className="text-slate-800" />
                </button>
            </div>
            <div className="flex justify-center mt-4">
                {feedbackItems.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => goToIndex(index)}
                        className={`w-2 h-2 p-4 rounded-full mx-1 ${
                            index === currentIndex ? 'bg-white' : 'bg-white/50'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};

export default FeedbackGallery;