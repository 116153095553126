import { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const galleryItems = [
    { id: 1, src:  'images/img178.jpg', alt: 'Gallery item 1' },
    { id: 2, src: 'images/img179.jpg', alt: 'Gallery item 2' },
    { id: 3, src: 'images/img180.jpg', alt: 'Gallery item 3' },
    { id: 4, src: 'images/img195.jpg', alt: 'Gallery item 4' },
    { id: 5, src: 'images/img197.jpg', alt: 'Gallery item 5' },
    { id: 6, src: 'images/img199.jpg', alt: 'Gallery item 6' },
    { id: 7, src: 'images/img200.jpg', alt: 'Gallery item 7' },
    { id: 8, src: 'images/img201.jpg', alt: 'Gallery item 8' },
];

const Gallery = () => {
    const [currentIndex, setCurrentIndex] = useState(4);
    const [orderedItems, setOrderedItems] = useState([]);

    useEffect(() => {
        const reorderedItems = [...galleryItems];
        const offset = currentIndex - Math.floor(reorderedItems.length / 2);

        const reorder = (items, offset) => {
            if (offset > 0) {
                return [...items.slice(offset), ...items.slice(0, offset)];
            } else if (offset < 0) {
                const positiveOffset = items.length + offset;
                return [...items.slice(positiveOffset), ...items.slice(0, positiveOffset)];
            }
            return items;
        };

        setOrderedItems(reorder(reorderedItems, offset));
    }, [currentIndex]);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? galleryItems.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === galleryItems.length - 1 ? 0 : prevIndex + 1
        );
    };

    const goToIndex = (index) => {
        setCurrentIndex(index);
    };

    const getItemStyle = (index) => {
        const centerIndex = Math.floor(orderedItems.length / 2);
        const distance = Math.abs(index - centerIndex);
        const scale = 1 - (distance * 0.2);
        const opacity = 1 - (distance * 0.2);
        let width = 192;
        width *= scale;
        return {
            width: `${width}px`,
            height: `${width}px`,
            opacity: opacity,
            marginLeft: '10px',
            marginRight: '10px',
        };
    };

    return (
        <div className="bg-teal-500 p-8 shadow-lg">
            <h2 className="text-4xl font-bold text-white mb-8 text-center">2023 Gallery</h2>
            <div className="relative overflow-hidden">
                <div className="flex justify-center items-center mb-4 h-64">
                    {orderedItems.map((item, index) => (
                        <img
                            key={item.id}
                            src={item.src}
                            alt={item.alt}
                            style={getItemStyle(index)}
                            className="shadow-md flex-shrink-0"
                        />
                    ))}
                </div>
                <button
                    onClick={goToPrevious}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white/50 p-2 rounded-full z-10"
                >
                    <ChevronLeft className="text-teal-800" />
                </button>
                <button
                    onClick={goToNext}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white/50 p-2 rounded-full z-10"
                >
                    <ChevronRight className="text-teal-800" />
                </button>
            </div>
            <div className="flex justify-center gap-1 mt-4">
                {galleryItems.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => goToIndex(index)}
                        className={`w-1 h-1 rounded-full ${
                            index === currentIndex ? 'bg-white p-4 -translate-y-1' : 'bg-white/50 p-3'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Gallery;
