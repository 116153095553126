import React from 'react';
import { Youtube, Twitter, Facebook, Linkedin, Instagram } from 'lucide-react';

export default function HealthExpoPartners() {
    return (
        <div className="bg-white p-4 font-sans">
            <div className="bg-red-600 text-white p-2 flex flex-wrap items-center justify-center mb-4">
                <span className="mr-4 font-bold mb-2 w-full text-center sm:w-auto sm:mb-0">Reach us on Social Media</span>
                <div className="flex justify-center w-full sm:w-auto">
                    <Youtube size={20} className="mx-2" />
                    <Twitter size={20} className="mx-2" />
                    <Facebook size={20} className="mx-2" />
                    <Linkedin size={20} className="mx-2" />
                    <Instagram size={20} className="mx-2" />
                </div>
            </div>

            <div className="flex flex-col sm:flex-row">
                <div className="w-full sm:w-1/3 pr-0 sm:pr-4 mb-4 sm:mb-0">
                    <h1 className="text-4xl font-bold text-teal-500 leading-tight text-center sm:text-left">
                        Meet<br />
                        HEALTH<br />
                        EXP<span className="text-red-500">O</span>IRAQ<br />
                        Partners
                    </h1>
                </div>

                <div className="w-full sm:w-2/3 border-2 border-teal-500 rounded-lg p-4">
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                        <div className="col-span-2 sm:col-span-4">
                            <h2 className="text-sm font-semibold border-b border-gray-300 pb-1">Official bodies</h2>
                            <div className="flex flex-wrap justify-between items-center mt-2">
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                            </div>
                        </div>

                        <div className="col-span-1 sm:col-span-2">
                            <h2 className="text-sm font-semibold border-b border-gray-300 pb-1">Venue</h2>
                            <div className="mt-2">
                                <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                            </div>
                        </div>

                        <div className="col-span-1 sm:col-span-2">
                            <h2 className="text-sm font-semibold border-b border-gray-300 pb-1">In cooperation with</h2>
                            <div className="mt-2">
                                <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                            </div>
                        </div>

                        <div className="col-span-2 sm:col-span-3">
                            <h2 className="text-sm font-semibold border-b border-gray-300 pb-1">Platinum sponsor</h2>
                            <div className="flex flex-wrap justify-between items-center mt-2">
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                            </div>
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                            <h2 className="text-sm font-semibold border-b border-gray-300 pb-1">Gold sponsor</h2>
                            <div className="mt-2">
                                <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                            </div>
                        </div>

                        <div className="col-span-2 sm:col-span-4">
                            <h2 className="text-sm font-semibold border-b border-gray-300 pb-1">Strategic partner</h2>
                            <div className="flex flex-wrap justify-between items-center mt-2">
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                            </div>
                        </div>

                        <div className="col-span-2 sm:col-span-4">
                            <h2 className="text-sm font-semibold border-b border-gray-300 pb-1">Official hotels</h2>
                            <div className="flex flex-wrap justify-between items-center mt-2">
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                                <div className="w-12 h-12 bg-gray-200 rounded-full mb-2"></div>
                            </div>
                        </div>

                        <div className="col-span-2 sm:col-span-4">
                            <h2 className="text-sm font-semibold border-b border-gray-300 pb-1">Transportation partner</h2>
                            <div className="mt-2">
                                <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}