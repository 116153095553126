import React from 'react';

const HealthExpoComponent = () => {
    return (
        <div className="relative min-h-screen w-full bg-black text-white overflow-hidden">
            <div className="absolute inset-0 bg-[url('images/conf.jpg')] bg-cover bg-center opacity-20"></div>
            <div className="relative inset-0 flex flex-col justify-start items-center p-4 sm:p-6 overflow-y-auto">
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-8 text-cyan-800 mt-8 sm:mt-16 text-center">ABOUT HEALTH EXPO</h2>
                <div className="text-sm sm:text-base leading-relaxed text-center max-w-4xl">
                    <p className="mb-4 text-lg sm:text-xl">
                        HEALTH EXPO IRAQ has firmly established itself as a cornerstone event in the world of healthcare in Iraq, and the last edition was a
                        testimony to its unparalleled standing. Business Ground Group is the organizer of the only annual medical event affiliated with the
                        Ministry of Health (Health Expo Iraq) and under the supervision of the state company for drug and medical appliances (Kimadia). The
                        exhibition was held in Baghdad on the ground of Baghdad International Fair, where it was a gathering place for a variety of healthcare
                        professionals, companies, institutions, and NGOs from all over the world.
                    </p>
                    <p className="mb-4 text-lg sm:text-xl">
                        Health Expo Iraq provides an excellent platform for participants from different healthcare sectors like National, foreign, and Arab factories,
                        Scientific bureaus, pharmaceutical companies, Medical devices companies, Private sector hospitals, Health insurance companies, IT companies
                        Shipping & customs clearance companies to communicate seamlessly with industry leaders, delve into sessions rich in knowledge, and learn about
                        the evolving trends that shape the future of healthcare.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HealthExpoComponent;