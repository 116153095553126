import React from 'react';

const HealthExpoPromo = () => {
    return (
        <div className="bg-gradient-to-b from-sky-50 to-sky-100 p-4 md:p-8 flex flex-col md:flex-row justify-between items-center min-h-screen">
            <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <div className="mb-4">
                    <img src={"images/logo2.png"} alt={"Logo"} className="w-4/5 md:w-3/5 mx-auto md:mx-0"/>
                </div>
                <div className="space-y-2 text-center md:text-left md:ml-5 text-2xl md:text-3xl font-bold">
                    <p className="text-teal-700">Health Expo</p>
                    <p className="text-teal-700">Iraq 2023</p>
                    <p className="text-teal-700">Post-Show</p>
                    <p className="text-teal-700">Report is</p>
                    <p className="text-2xl md:text-3xl font-normal text-teal-700">Now Available !</p>
                </div>
            </div>
            <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
                <div className="relative w-64 h-48 md:w-96 md:h-72 mb-8">
                    <div className="absolute right-0 top-0 w-56 h-40 md:w-80 md:h-60 bg-transparent transform rotate-6">
                        <img src="images/cards.png" alt="Health Expo Report" className="w-full scale--img h-full object-cover" />
                    </div>
                </div>
                <button className="bg-red-600 text-white px-6 md:px-10 py-2 rounded-full text-xl md:text-3xl font-semibold hover:bg-red-700 transition-colors duration-300">
                    Download Now!
                </button>
            </div>
        </div>
    );
};

export default HealthExpoPromo;