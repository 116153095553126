import {
    Heart, Cross, Building2, Pill, Factory, Package, DollarSign, Ambulance,
    Stethoscope, Briefcase, Network, Shield, GraduationCap, Apple, Recycle, BedSingle,
    Syringe, Microscope, FileCheck, Bone, ClipboardCheck, HandshakeIcon, Users,
    TestTube, Scissors, Activity, Truck, Plane, Hospital, Users2, MonitorSmartphone
} from 'lucide-react';

const categories = [
    { icon: Heart, text: "Healthcare services & facilities" },
    { icon: Cross, text: "Manufacturers of medical devices & equipment" },
    { icon: Building2, text: "Healthcare infrastrucule" },
    { icon: Pill, text: "Pharmaceuticals & Related sectors" },
    { icon: Factory, text: "National factories" },
    { icon: Package, text: "Healthcare suppliers" },
    { icon: DollarSign, text: "Investors" },
    { icon: Ambulance, text: "Ambulance & Mobile hospitals" },
    { icon: Stethoscope, text: "Dentistry" },
    { icon: Briefcase, text: "Healthcare & Public services" },
    { icon: Network, text: "IT solutions" },
    { icon: Shield, text: "Medical isurance" },
    { icon: GraduationCap, text: "Medical universities" },
    { icon: Apple, text: "Nutrition & Sport" },
    { icon: Recycle, text: "Medical waste managemetn & recycleing" },
    { icon: BedSingle, text: "Hospitals" },
    { icon: Syringe, text: "Consumables & Consumer goods" },
    { icon: Microscope, text: "Imaging & Diagnostics" },
    { icon: FileCheck, text: "Customs clearance" },
    { icon: Bone, text: "Orthopedics" },
    { icon: ClipboardCheck, text: "Medical research" },
    { icon: Cross, text: "Sterilization companies" },
    { icon: HandshakeIcon, text: "Dealers & Scientific bureaus" },
    { icon: Users, text: "NGO" },
    { icon: TestTube, text: "Medical services & Laboratory equipment" },
    { icon: Scissors, text: "Dermatology , Laser surgery & Plastic surgery" },
    { icon: Activity, text: "Physiotherapy & Rehabilitation" },
    { icon: Truck, text: "Shipping & Transporting" },
    { icon: Plane, text: "Medical tourism" },
    { icon: Hospital, text: "Centers & Clinics" },
    { icon: Users2, text: "Associations & Syndicates" },
    { icon: MonitorSmartphone, text: "Medical appliances" },
];

const ProductCategories = () => {
    return (
        <div className="bg-teal-500 p-8">
            <h1 className="text-white text-4xl font-bold mb-8 text-center">Dedicated Product Categories</h1>
            <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 gap-4">
                {categories.map((category, index) => (
                    <div key={index} className="bg-teal-400 p-4 rounded-lg flex flex-col items-center justify-center text-center">
                        <category.icon className="text-white mb-2" size={24} />
                        <p className="text-white text-xs">{category.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductCategories;