import React from 'react';
import { Youtube, Twitter, Facebook, Linkedin, Instagram } from 'lucide-react';

const Reach = () => {
    return (
        <div>
            <div className="relative w-full h-[300px] sm:h-[400px] overflow-hidden">
                <div className="absolute inset-0 bg-[url('images/img195.jpg')] bg-cover bg-center"></div>
                <div className="absolute inset-0 bg-black opacity-50"></div>

                <div className="relative z-10 flex flex-col justify-end h-full px-4 sm:px-8 py-6 sm:py-12">
                    <div className="absolute z-10 top-4 sm:top-20 left-4 sm:left-8">
                        <h1 className="text-4xl sm:text-7xl font-black text-red-700">REACH</h1>
                        <p className="text-2xl sm:text-4xl ml-1 font-light text-white font-mono">CONFERENCE</p>
                    </div>
                    <div className="max-w-xl mt-12 sm:mt-24">
                        <p className="text-white font-medium text-sm sm:text-lg leading-relaxed">
                            The Ministry of Health is organizing Reach conference which is
                            an international platform, aims to gather specialists from
                            public, and private institutions, academia, and medical society
                            leaders to share ideas and experiences with each other,
                            improve the health system in Iraq, develop investment, and
                            partnerships between public, and private sectors.
                        </p>
                    </div>

                    <div className="absolute bottom-2 right-4 sm:right-32">
                        <button className="text-red-600 bg-white px-4 sm:px-10 py-1 rounded-full text-lg sm:text-3xl font-semibold transition-colors">
                            Become a Speaker
                        </button>
                    </div>
                </div>
            </div>
            <div className="bg-white p-4 font-sans">
                <div className="bg-red-600 p-3 sm:p-5 text-white text-center mb-4">
                    <p className="font-bold text-xl sm:text-3xl mb-2 sm:mb-4">Reach us on Social Media</p>
                    <div className="flex justify-center sm:justify-around max-w-xs sm:max-w-md mx-auto">
                        <Youtube size={20} className="mx-2"/>
                        <Twitter size={20} className="mx-2"/>
                        <Facebook size={20} className="mx-2"/>
                        <Linkedin size={20} className="mx-2"/>
                        <Instagram size={20} className="mx-2"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reach;